<template>
  <div>
    <CRow>
      <CCol>
        <h2>
          {{ company.human_name }} Recurring Invoice Breakdown
        </h2>
        <span><i>Data is pulled directly from ZOHO.</i></span>
      </CCol>
    </CRow>
    <div class="col-6">
      <CRow>
        <CCol>
          <RDataTable
            :items="invoices"
            :fields="columns"
            :table-props="tableProps"
          >
            <template #billed="{ item }">
              <td>
                {{ parseInt(item.total) }} {{ formatZohoBillingFrequency(item) }}
              </td>
            </template>
          </RDataTable>
        </CCol>
      </CRow>
      <CRow class="justify-content-end">
        <CCol sm="4">
          <h5 class="text-right">
            <b>Zoho Total:</b>
          </h5>
        </CCol>
        <CCol sm="4">
          <h5><b>{{ monthlyRate }} / Monthly</b></h5>
        </CCol>
      </CRow>
      <CRow class="justify-content-end">
        <CCol sm="4">
          <h5 class="text-right">
            <b>Count:</b>
          </h5>
        </CCol>
        <CCol sm="4">
          <h5><b>{{ total }}</b></h5>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import { endOfMonth } from 'date-fns'

import { RDataTable } from '@/components/tables'
import { RooofAPI } from '@/services/api/resources'
import { formatDate } from '@/utils'
import constants from '@/utils/constants'
import { capitalize } from '@/utils/strings'

const columns = [
  {
    key: 'customer_name',
    label: 'Recurring Invoice Name',
    _style: 'width: 60%'
  },
  {
    key: 'billed',
    label: 'Billed',
    _style: 'width: 40%'
  }
]
export default {
  name: 'MonthlySummaryRecurringInvoiceDetails',
  components: {
    RDataTable
  },
  data () {
    return {
      tableProps: {
        sorterValue: { column: 'customer_name', asc: 'true' }
      },
      total: 0,
      pageSize: 25,
      invoices: [],
      billingFrequency: constants.rooof.BillingFrequency.getAsObject({ inverted: true })
    }
  },
  computed: {
    company () {
      return this.$store.getters['rooof/getCompanyById'](this.$route.params.cid)
    },
    monthlyRate () {
      let total = 0
      for (const invoice of this.invoices) {
        if (invoice.billing_frequency) {
          total += (parseFloat(invoice.total) / invoice.billing_frequency)
        } else {
          total += parseFloat(invoice.total)
        }
      }
      return `$${total.toFixed(2)}`
    }
  },
  watch: {
    company: {
      immediate: true,
      handler () {
        this.setSelectedCompany()
      }
    }
  },
  created () {
    this.columns = columns
    this.fetchInvoices(this.$route.params.cid)
  },
  methods: {
    /**
     * Fetch the list of recurring invoices for the company
     */
    async fetchInvoices (id) {
      const monthEnd = formatDate(endOfMonth(new Date()), 'yyyy-MM-dd')
      const params = new URLSearchParams()
      params.append('page_size', this.pageSize)
      params.append('company_id', id)
      params.append('status', 'active')
      params.append('end_date__gte', monthEnd)
      params.set('page', '1')
      const response = await RooofAPI.recurringInvoices.list({ params })
      if (response) {
        let data = response.data.results
        this.total = response.data.count

        // Create and send the remaining requests in parallel
        const numPages = Math.ceil(this.total / this.pageSize)
        const responses = []
        for (let page = 2; page <= numPages; page++) {
          params.set('page', page.toString())
          responses.push(await RooofAPI.recurringInvoices.list({ params }))
        }
        for (const response of responses) {
          if (response) {
            data = data.concat(response.data.results)
          }
        }
        this.invoices = data
      }
    },
    /**
     * Format billing choice to be displayed
     *
     * @param {Object} row - invoice
     * @return {String}
     */
    formatZohoBillingFrequency (row) {
      if (!row.billing_frequency) {
        return ''
      }
      return `/ ${capitalize(this.billingFrequency[row.billing_frequency].toLowerCase())}`
    },
    /**
     * Sets the selected company in the store.
     */
    setSelectedCompany () {
      const selectedCompany = {
        human_name: this.company.human_name,
        id: this.company.id
      }
      this.$store.commit('set', ['selectedCompany', selectedCompany])
    }
  }
}
</script>
